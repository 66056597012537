@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  @apply text-slate-900;
}

.react-datepicker {
  @apply !rounded-none !border-none;
}

.react-datepicker__header {
  @apply !rounded-none !border-b-0;
}

.react-datepicker__navigation-icon {
  @apply !top-1;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range {
  @apply !bg-primary-700/50;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range,
.react-datepicker__day--range-end,
.react-datepicker__month-text--keyboard-selected {
  @apply !bg-primary-700 hover:!bg-primary-500;
}

.success-icon-svg {
  animation: scale-once 0.8s;
}

.success-icon-path {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: fill-path 3s linear forwards;
}

@keyframes fill-path {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes scale-once {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

div.up2rem {
  margin-top: -1.5rem;
}

.pushRight {
  margin-left: auto;
}

.react-datepicker__year-text--disabled {
  visibility: hidden;
}

.gap-x-tight {
  column-gap: 0.1rem;
}

.tippyWide {
  text-align: center;
  width: 215px;
}

.adyen-pe-modal--small {
  transform: none !important;
}
